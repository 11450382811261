// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$feature-vote-primary: mat.define-palette(mat.$indigo-palette);
$feature-vote-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$feature-vote-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$feature-vote-theme: mat.define-light-theme(
  (
    color: (
      primary: $feature-vote-primary,
      accent: $feature-vote-accent,
      warn: $feature-vote-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($feature-vote-theme);

@import 'styles/animations';
@import 'styles/fonts';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl font-sans font-black mb-4;
  }

  h2 {
    @apply text-3xl font-sans font-black mb-4;
  }

  h3 {
    @apply text-2xl font-sans font-black mb-4;
  }

  h4 {
    @apply text-xl font-sans font-black mb-4;
  }

  h5 {
    @apply text-lg font-sans font-black mb-4;
  }

  a {
    @apply hover:text-accent transition duration-300 ease-in-out font-mono;
  }

  label {
    @apply font-mono text-sm block text-gray-800 font-bold;
  }

  p {
    @apply max-w-xl font-mono text-base;
  }
}

@screen lg {
  h1 {
    @apply text-7xl font-sans font-black mb-4;
  }

  h2 {
    @apply text-6xl font-sans font-black mb-4;
  }

  h3 {
    @apply text-5xl font-sans font-black mb-4;
  }

  h4 {
    @apply text-4xl font-sans font-black mb-4;
  }

  h5 {
    @apply text-2xl font-sans font-black mb-4;
  }
}

input,
select {
  @apply h-11;
}

input,
select,
textarea {
  @apply w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-indigo-500 transition duration-300 ease-in-out;
}

button {
  @apply border-transparent hover:border-accent hover:text-accent focus:outline-none focus:border-indigo-500 transition duration-300 ease-in-out;
}

html {
  scroll-behavior: smooth;
}

body {
  @apply bg-gray-200;
  height: 100vh;
}

.mdc-button__label {
  color: white;
}

.mat-mdc-snack-bar-container {
  &.failure-snackbar {
    --mdc-snackbar-container-color: #fa113d;
  }

  &.success-snackbar {
    --mdc-snackbar-container-color: #4bb543;
  }
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 14px;
  border: 2px solid white;
}
