@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/Karla-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 800;
  src: local(''), url('../assets/fonts/Karla-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/Inconsolata-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 800;
  src: local(''), url('../assets/fonts/Inconsolata-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 200;
  src: local(''),
    url('../assets/fonts/Oxanium-ExtraLight.ttf') format('truetype');
}
